import React, { useState, useEffect, useRef } from 'react';
import Mapa from '../component/Map'; // Asegúrate de importar el componente Mapa

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { useFloating, autoUpdate, useInteractions, FloatingArrow, arrow, offset, useClick, shift } from '@floating-ui/react';

import {
  TOKEN_KEY,
} from "../Services/localStorage";

import '../assets/css/styles.css'

function App() {
  const navigate = useNavigate();
  const [sensores, setSensores] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);


  useEffect(() => {
    const storedToken = localStorage.getItem(TOKEN_KEY);
    if (!storedToken) {
      navigate('/login');
    }
  }, [navigate]);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), shift({ padding: 20 }), arrow({ element: arrowRef })]
  });

  const click = useClick(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
  ]);

  const handleLogOut = () => {
    localStorage.removeItem(TOKEN_KEY);

    navigate('/login');
  };

  useEffect(() => {
    let ws;
    const connectWebSocket = () => {
      if (ws) {
        ws.close();
      }
      ws = new WebSocket('wss://ws.devs.xrob.com.ar/ws');
      // ws = new WebSocket('ws://localhost:8080');
      ws.onopen = () => {
        console.log('Conectado al WebSocket');
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        if (data[0]?.color) {
          setSensores(data);
        }
      };

      ws.onclose = (event) => {
        console.log('WebSocket desconectado. Intentando reconectar...');

        setTimeout(() => {
          connectWebSocket();
        }, 1000); 
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.close(); 
      };
    };

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  return (
    <div className="App">
      <div className="sidebar">

        {/* Contenido del sidebar, por ejemplo, un menú */}
      </div>
      <div className="content">
        <div className="navbar shadow-2xl">
        <img src="https://www.aguasdeltucuman.com.ar/images/logosat-100x100.png" className="logo" />
          
          <h5 className='mb-0 text-white'>DEMO - MAPA</h5>
          {/* <div className="logo"></div>  */}

          <div className='d-flex align-items-center justify-content-center m-2 gap-2 bg-primary p-2 rounded-3 cursor-pointer' onClick={handleLogOut} ref={refs.setReference} {...getReferenceProps()}>
            <div className='d-flex align-items-center justify-content-center bg-white rounded-circle' style={{ height: '34px', width: '34px' }}>
              <FontAwesomeIcon icon={faUser} className='fs-5 p-2 text-muted' />
            </div>
            <div className='d-flex flex-column align-items-start text-white'>
              <div className="user-small m-0 p-0">admin</div>
              <div className="user-bold m-0 p-0">Admin1</div>
            </div>
          </div>

          {isOpen && (
            <div
              ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}
              className="d-flex flex-column position-absolute bg-white z-1000 rounded-3 p-2 shadow-lg"
            >
              <FloatingArrow
                ref={arrowRef}
                context={context}
                tipRadius={3}
                fill="white"
              />
              <div className='d-flex flex-row gap-2 align-items-center p-3'>
                <div className='d-flex align-items-center justify-content-center bg-primary rounded-circle' style={{ height: '42px', width: '42px' }}>
                  <FontAwesomeIcon icon={faUser} className='fs-5 text-white' />
                </div>
                <div className='d-flex flex-column align-items-start'>
                  <div className="user-bold m-0 p-0 text-black">Admin1</div>
                  <div className="user-small m-0 p-0 text-muted">admin@providers.com.ar</div>
                </div>
              </div>
              <div className="h-0 mb-2 border" />
              <div className='d-flex flex-row align-items-center gap-2 p-2 rounded-2 hover-LO' type="button" onClick={handleLogOut}>
                <FontAwesomeIcon icon={faRightFromBracket} className='text-primary fs-5' />
                <div className="fw-semibold m-0 p-0 text-muted">Salir</div>
              </div>
            </div>
          )}
          {/* Reemplazar con <img src="path/to/logo.png" className="logo" /> si tienes una imagen de logo */}
          {/* Otros elementos del navbar */}
        </div>
        <Mapa sensores={sensores} />
      </div>
    </div>
  );
}

export default App;
