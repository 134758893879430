import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";

import imgAmarillo from "../assets/img/amarillo.png";
import imgverde from "../assets/img/verde.png";
import imgrojo from "../assets/img/rojo.webp";

const STATUS = {
  OK: "Ok",
  FAILED: "Falla",
  DISCONNECTED: "Desconectado",
  MOCK: "Mock",
};

const puntoIcono = (status = STATUS.OK, width = 35, height = 35) => {
  let colorSVG;
  let iconSVG;

  if (status.toLocaleLowerCase() === STATUS.DISCONNECTED.toLocaleLowerCase()) {
    iconSVG = `
      <svg width=${width} height=${height} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M128 224L135.552 231.52C131.392 235.68 124.64 235.712 120.48 231.552L120.448 231.52L120.384 231.456L120.192 231.264L119.52 230.592C115.744 226.72 112.064 222.784 108.416 218.784C99.296 208.768 90.56 198.432 82.208 187.776C72.704 175.52 62.976 161.632 55.584 147.936C48.352 134.496 42.656 119.968 42.656 106.656C42.656 58.24 80.992 21.344 128 21.344C175.008 21.344 213.344 58.24 213.344 106.656C213.344 119.968 207.648 134.528 200.416 147.936C193.024 161.664 183.296 175.52 173.792 187.776C162.144 202.72 149.696 216.992 136.512 230.592L135.84 231.264L135.648 231.456L135.584 231.52L128 224ZM128 138.656C145.664 138.656 160 124.32 160 106.656C160 88.992 145.664 74.656 128 74.656C110.336 74.656 96 88.992 96 106.656C96 124.32 110.336 138.656 128 138.656Z" fill="#EF1818"/>
<path d="M126.624 65.568C127.584 65.568 128.512 65.568 129.472 65.568C129.664 65.728 129.888 65.632 130.08 65.632C130.176 65.632 130.304 65.632 130.4 65.632C130.56 65.6 130.752 65.696 130.912 65.568C131.008 65.568 131.136 65.568 131.232 65.568C132.096 65.728 132.992 65.696 133.888 65.76C137.6 66.08 141.056 67.2 144.416 68.672C152.544 72.256 158.88 77.952 163.36 85.632C165.824 89.856 167.52 94.368 168.256 99.232C168.448 100.384 168.384 101.568 168.576 102.752C168.576 102.912 168.576 103.072 168.576 103.232C168.448 103.36 168.512 103.552 168.512 103.712C168.544 103.872 168.448 104.032 168.576 104.192C168.576 104.416 168.576 104.608 168.576 104.832C168.448 105.152 168.448 105.472 168.576 105.792C168.576 106.016 168.576 106.208 168.576 106.432C168.448 106.752 168.448 107.072 168.576 107.392C168.576 107.616 168.576 107.808 168.576 108.032C168.448 108.16 168.512 108.32 168.512 108.48C168.512 108.608 168.48 108.736 168.576 108.832C168.576 109.088 168.576 109.344 168.576 109.632C168.32 111.392 168.352 113.184 167.936 114.944C166.432 121.472 163.552 127.36 159.232 132.48C155.744 136.608 151.584 139.968 146.784 142.432C142.688 144.544 138.336 146.048 133.728 146.528C130.336 146.88 126.88 146.88 123.488 146.592C114.944 145.856 107.52 142.368 101.152 136.768C93.696 130.176 89.12 121.92 87.744 112C87.36 109.152 87.456 106.272 87.552 103.392C87.616 100.992 87.872 98.624 88.48 96.288C90.24 89.376 93.536 83.296 98.464 78.112C104.512 71.744 111.808 67.744 120.416 66.048C121.856 65.76 123.328 65.856 124.8 65.632C125.024 65.632 125.216 65.632 125.44 65.632C125.536 65.76 125.696 65.696 125.824 65.696C125.92 65.696 126.016 65.696 126.112 65.696C126.24 65.632 126.464 65.728 126.624 65.568Z" fill="white"/>
<path d="M148.48 118.944C144.032 114.432 139.552 109.952 135.2 105.664C134.976 105.472 134.72 105.152 134.72 104.992C134.72 104.896 134.784 104.704 135.168 104.352C137.888 101.696 140.576 98.976 143.232 96.352C144.928 94.656 146.592 92.96 148.288 91.296C149.536 90.048 150.08 88.672 149.952 87.104C149.76 85.088 148.672 83.68 146.784 83.008C144.864 82.304 143.072 82.72 141.632 84.16L139.744 86.048C136 89.792 132.128 93.632 128.352 97.472C128.16 97.696 127.84 97.952 127.68 97.952C127.584 97.952 127.392 97.888 127.04 97.504C124.448 94.848 121.76 92.192 119.2 89.6C117.472 87.872 115.712 86.144 113.984 84.384C112.704 83.104 111.36 82.56 109.792 82.72C107.776 82.912 106.368 84 105.696 85.888C105.024 87.808 105.44 89.632 106.944 91.136C108.256 92.448 109.6 93.792 110.912 95.104C113.888 98.08 116.992 101.184 120.032 104.192C120.416 104.576 120.576 104.8 120.576 104.96C120.576 105.12 120.416 105.376 120.064 105.696C117.184 108.512 114.272 111.424 111.488 114.208C109.984 115.712 108.48 117.248 106.944 118.752C105.856 119.84 105.312 120.896 105.216 122.144C105.056 124.096 106.176 126.016 107.936 126.848C108.64 127.2 109.376 127.36 110.112 127.36C111.36 127.36 112.544 126.848 113.536 125.888C118.272 121.184 122.528 116.96 126.816 112.608C127.072 112.32 127.424 112 127.648 112C127.808 112 128.096 112.192 128.448 112.544C131.264 115.456 134.176 118.336 136.992 121.152C138.496 122.656 140 124.16 141.504 125.664C142.656 126.816 143.872 127.36 145.376 127.36H145.408H145.44C145.472 127.36 145.536 127.36 145.6 127.328C145.792 127.296 146.048 127.264 146.304 127.232C148.064 126.816 149.408 125.536 149.888 123.904C150.368 122.208 149.856 120.352 148.48 118.944Z" fill="#EF1818"/>
</svg>

      `;
  } else if (status.toLocaleLowerCase() === STATUS.FAILED.toLocaleLowerCase()) {
    iconSVG = `<svg width=${width} height=${height} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M128 224L135.552 231.52C131.392 235.68 124.64 235.712 120.48 231.552L120.448 231.52L120.384 231.456L120.192 231.264L119.52 230.592C115.744 226.72 112.064 222.784 108.416 218.784C99.296 208.768 90.56 198.432 82.208 187.776C72.704 175.52 62.976 161.632 55.584 147.936C48.352 134.496 42.656 119.968 42.656 106.656C42.656 58.24 80.992 21.344 128 21.344C175.008 21.344 213.344 58.24 213.344 106.656C213.344 119.968 207.648 134.528 200.416 147.936C193.024 161.664 183.296 175.52 173.792 187.776C162.144 202.72 149.696 216.992 136.512 230.592L135.84 231.264L135.648 231.456L135.584 231.52L128 224ZM128 138.656C145.664 138.656 160 124.32 160 106.656C160 88.992 145.664 74.656 128 74.656C110.336 74.656 96 88.992 96 106.656C96 124.32 110.336 138.656 128 138.656Z" fill="#FFA101"/>
      <path d="M126.624 65.568C127.584 65.568 128.512 65.568 129.472 65.568C129.664 65.728 129.888 65.632 130.08 65.632C130.176 65.632 130.304 65.632 130.4 65.632C130.56 65.6 130.752 65.696 130.912 65.568C131.008 65.568 131.136 65.568 131.232 65.568C132.096 65.728 132.992 65.696 133.888 65.76C137.6 66.08 141.056 67.2 144.416 68.672C152.544 72.256 158.88 77.952 163.36 85.632C165.824 89.856 167.52 94.368 168.256 99.232C168.448 100.384 168.384 101.568 168.576 102.752C168.576 102.912 168.576 103.072 168.576 103.232C168.448 103.36 168.512 103.552 168.512 103.712C168.544 103.872 168.448 104.032 168.576 104.192C168.576 104.416 168.576 104.608 168.576 104.832C168.448 105.152 168.448 105.472 168.576 105.792C168.576 106.016 168.576 106.208 168.576 106.432C168.448 106.752 168.448 107.072 168.576 107.392C168.576 107.616 168.576 107.808 168.576 108.032C168.448 108.16 168.512 108.32 168.512 108.48C168.512 108.608 168.48 108.736 168.576 108.832C168.576 109.088 168.576 109.344 168.576 109.632C168.32 111.392 168.352 113.184 167.936 114.944C166.432 121.472 163.552 127.36 159.232 132.48C155.744 136.608 151.584 139.968 146.784 142.432C142.688 144.544 138.336 146.048 133.728 146.528C130.336 146.88 126.88 146.88 123.488 146.592C114.944 145.856 107.52 142.368 101.152 136.768C93.696 130.176 89.12 121.92 87.744 112C87.36 109.152 87.456 106.272 87.552 103.392C87.616 100.992 87.872 98.624 88.48 96.288C90.24 89.376 93.536 83.296 98.464 78.112C104.512 71.744 111.808 67.744 120.416 66.048C121.856 65.76 123.328 65.856 124.8 65.632C125.024 65.632 125.216 65.632 125.44 65.632C125.536 65.76 125.696 65.696 125.824 65.696C125.92 65.696 126.016 65.696 126.112 65.696C126.24 65.632 126.464 65.728 126.624 65.568Z" fill="white"/>
      <path d="M127.392 132.256C126.848 132.032 126.24 131.968 125.696 131.744C122.976 130.624 121.344 127.808 121.664 124.768C121.984 121.952 124.288 119.456 127.072 118.976C131.488 118.208 135.296 121.6 135.04 126.048C134.848 129.248 132.544 131.744 129.376 132.16C129.312 132.16 129.248 132.16 129.216 132.256C128.608 132.256 128 132.256 127.392 132.256Z" fill="#FFA101"/>
      <path d="M137.12 87.04C136.928 88.928 136.48 90.912 136.16 92.928C135.296 97.888 134.432 102.816 133.568 107.776C133.344 108.992 133.152 110.208 132.928 111.424C132.48 113.696 130.688 115.2 128.352 115.2C126.048 115.2 124.192 113.696 123.808 111.392C122.752 105.504 121.728 99.616 120.704 93.728C120.32 91.552 119.84 89.408 119.616 87.2C119.2 82.752 122.656 78.528 127.104 77.984C132.032 77.376 136.096 80.352 137.024 85.216C137.12 85.76 137.152 86.304 137.12 87.04Z" fill="#FFA101"/>
      </svg>
      `;
  } else if (status.toLocaleLowerCase() === STATUS.MOCK.toLocaleLowerCase()) {
    // Lista de imágenes posibles para el estado "Mock"
    const imagenes = [imgrojo, imgAmarillo, imgverde];
    // Generar un índice aleatorio basado en la longitud del array de imágenes
    const indiceAleatorio = Math.floor(Math.random() * imagenes.length);
    // Seleccionar la URL de la imagen basada en el índice aleatorio
    iconSVG = `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="${colorSVG}" version="1.1" id="Capa_1" width="40px" height="40px" viewBox="0 0 49 49" xml:space="preserve">
<g>
	<path d="M24.5,0C14.465,0,6.329,8.136,6.329,18.171C6.329,33.076,24.5,49,24.5,49s18.171-15.107,18.171-30.828   C42.671,8.136,34.535,0,24.5,0z M24.5,29.197c-6.202,0-11.229-5.029-11.229-11.229c0-6.202,5.026-11.229,11.229-11.229   c6.201,0,11.229,5.027,11.229,11.229C35.729,24.168,30.701,29.197,24.5,29.197z"/>
</g>
<circle cx="46" cy="105" r="10" fill="black" filter="url(#sombra)" />
</svg>`;
  } else {
    iconSVG = `
      <svg width=${width} height=${height} viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M128 224L135.552 231.52C131.392 235.68 124.64 235.712 120.48 231.552L120.448 231.52L120.384 231.456L120.192 231.264L119.52 230.592C115.744 226.72 112.064 222.784 108.416 218.784C99.296 208.768 90.56 198.432 82.208 187.776C72.704 175.52 62.976 161.632 55.584 147.936C48.352 134.496 42.656 119.968 42.656 106.656C42.656 58.24 80.992 21.344 128 21.344C175.008 21.344 213.344 58.24 213.344 106.656C213.344 119.968 207.648 134.528 200.416 147.936C193.024 161.664 183.296 175.52 173.792 187.776C162.144 202.72 149.696 216.992 136.512 230.592L135.84 231.264L135.648 231.456L135.584 231.52L128 224ZM128 138.656C145.664 138.656 160 124.32 160 106.656C160 88.992 145.664 74.656 128 74.656C110.336 74.656 96 88.992 96 106.656C96 124.32 110.336 138.656 128 138.656Z" fill="#00B900"/>
      <path d="M128.096 103.968C127.872 103.648 127.872 103.328 128.096 103.008C128.096 103.328 128.096 103.648 128.096 103.968Z" fill="#10BD10"/>
      <path d="M128.096 105.536C127.872 105.216 127.872 104.896 128.096 104.576C128.096 104.896 128.096 105.216 128.096 105.536Z" fill="#10BD10"/>
      <path d="M126.624 65.568C127.584 65.568 128.512 65.568 129.472 65.568C129.664 65.728 129.888 65.632 130.08 65.632C130.176 65.632 130.304 65.632 130.4 65.632C130.56 65.6 130.752 65.696 130.912 65.568C131.008 65.568 131.136 65.568 131.232 65.568C132.096 65.728 132.992 65.696 133.888 65.76C137.6 66.08 141.056 67.2 144.416 68.672C152.544 72.256 158.88 77.952 163.36 85.632C165.824 89.856 167.52 94.368 168.256 99.232C168.448 100.384 168.384 101.568 168.576 102.752C168.576 102.912 168.576 103.072 168.576 103.232C168.448 103.36 168.512 103.552 168.512 103.712C168.544 103.872 168.448 104.032 168.576 104.192C168.576 104.416 168.576 104.608 168.576 104.832C168.448 105.152 168.448 105.472 168.576 105.792C168.576 106.016 168.576 106.208 168.576 106.432C168.448 106.752 168.448 107.072 168.576 107.392C168.576 107.616 168.576 107.808 168.576 108.032C168.448 108.16 168.512 108.32 168.512 108.48C168.512 108.608 168.48 108.736 168.576 108.832C168.576 109.088 168.576 109.344 168.576 109.632C168.32 111.392 168.352 113.184 167.936 114.944C166.432 121.472 163.552 127.36 159.232 132.48C155.744 136.608 151.584 139.968 146.784 142.432C142.688 144.544 138.336 146.048 133.728 146.528C130.336 146.88 126.88 146.88 123.488 146.592C114.944 145.856 107.52 142.368 101.152 136.768C93.696 130.176 89.12 121.92 87.744 112C87.36 109.152 87.456 106.272 87.552 103.392C87.616 100.992 87.872 98.624 88.48 96.288C90.24 89.376 93.536 83.296 98.464 78.112C104.512 71.744 111.808 67.744 120.416 66.048C121.856 65.76 123.328 65.856 124.8 65.632C125.024 65.632 125.216 65.632 125.44 65.632C125.536 65.76 125.696 65.696 125.824 65.696C125.92 65.696 126.016 65.696 126.112 65.696C126.24 65.632 126.464 65.728 126.624 65.568Z" fill="white"/>
      <path d="M156.48 91.392C156.48 93.312 155.84 94.656 154.688 95.744C148.32 101.696 141.952 107.68 135.552 113.632C130.496 118.336 125.44 123.04 120.416 127.776C119.872 128.288 119.648 128.192 119.2 127.712C113.216 121.248 107.2 114.816 101.184 108.384C99.616 106.72 99.104 104.8 99.872 102.624C100.64 100.544 102.208 99.36 104.416 99.104C106.304 98.912 107.84 99.648 109.12 101.056C112.64 104.832 116.192 108.608 119.68 112.416C120.16 112.928 120.416 112.96 120.928 112.48C126.592 107.136 132.288 101.856 137.952 96.544C141.088 93.632 144.192 90.72 147.36 87.84C149.12 86.24 151.136 85.888 153.312 86.848C155.392 87.712 156.384 89.44 156.48 91.392Z" fill="#00B900"/>
      </svg>`;
  }

  return new L.divIcon({
    html: iconSVG,
    className: "svg-icon",
    iconSize: [width, height],
    iconAnchor: [12, 40],
    popupAnchor: [6, -35], // Ajusta según la necesidad
  });
};
const getRandomInfo = () => {
  const infos = [
    "Información aleatoria 1",
    "Información aleatoria 2",
    "Información aleatoria 3",
    // Agrega tantas opciones como desees
  ];
  const randomIndex = Math.floor(Math.random() * infos.length);
  return infos[randomIndex];
};

function formatDate(dateString) {
  const daysOfWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  const date = new Date(dateString);
  date.setHours(date.getHours() - 3); // Restar 3 horas
  const dayOfWeek = daysOfWeek[date.getDay()];
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // El mes es indexado desde 0, por eso se suma 1
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${dayOfWeek} ${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const Mapa = ({ sensores = null }) => {
  return (
    <MapContainer
      center={[-26.8140113, -65.275586]}
      zoom={12}
      className="MapContainer"
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {sensores.map((sensor) => (
        <Marker
          key={sensor.id}
          position={sensor.coordenadas}
          icon={puntoIcono(sensor.estado)}
        >
          <Popup>
            <div>
              <div
                className="popup-title"
                style={{
                  backgroundColor: "ligthGrey",
                  color: "black",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <img
                  src={sensor.logo}
                  style={{ marginRight: "5px", height: "20px" }}
                  alt="Logo"
                />
                <b>{sensor.id}</b>
              </div>
              <div className="popup-body">
                <p className="p0">
                  {" "}
                  <b>Id:</b> {sensor.id}
                </p>
                <p className="p0">
                  <b>Direccion:</b> {sensor.direccion}
                </p>
                <p className="p0">
                  <b>Estado:</b> {sensor.estado}
                </p>
                <p className="p0">
                  <b>Tensión:</b> {sensor.tension} V
                </p>
                <p className="p0">
                  <b>Corriente:</b> {sensor.corriente} A
                </p>
                <p className="p0">
                  <b>Ultima lectura:</b> {formatDate(sensor.ultima_lectura)}
                </p>
                <p className="p0">
                  <b>Latitud:</b> {sensor.coordenadas[0]}
                </p>
                <p className="p0">
                  <b>Altitud</b> {sensor.coordenadas[1]}
                </p>
              </div>
              <div className="popup-footer">debug</div>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Mapa;
