import React, { useState, useEffect } from 'react';
import { Col, Row, Card, CardBody } from "reactstrap";
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    TOKEN_KEY,
} from "../../Services/localStorage";

import '../../assets/css/styles.css'

import img from '../../assets/img/images_processed.png';

/**
 * Login component
 */
export default function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setLoggedIn] = useState(false);

    const usersListString = process.env.REACT_APP_USERS_LIST;
    const usersList = usersListString.split(',');

    useEffect(() => {
        const storedToken = localStorage.getItem(TOKEN_KEY);
        if (storedToken) {
            navigate('/home');
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();

        const foundUser = usersList.find(user => {
            const [userEmail, userPassword] = user.split(':');
            return userEmail === email && userPassword === password;
        });

        if (foundUser) {
            const token = 'tokenDePrueba';
            localStorage.setItem(TOKEN_KEY, token);
            setLoggedIn(true);
        } else {
            alert('Email o contraseña incorrectos')
        }
    };

    if (isLoggedIn) {
        return <Navigate to="/home" />;
    }

    return (
        <>
            <section className="cover-user bg-gradient-p">
                <div className="container-fluid px-0">
                    <Row className="g-0 position-relative">
                        <Col className="cover-my-30 order-2">
                            <div className="d-flex align-items-center justify-content-center">
                                <Row>
                                    <div className="col-12">
                                        <div className="d-flex flex-column min-h-100">

                                            <div className="title-heading my-lg-auto">
                                                <Card className="login-page border-0 p-5 rounded-4 m-5 m-lg-0" style={{ zIndex: 1 }}>
                                                <div style={{textAlign:"center"}}>
                                                <img src="https://www.aguasdeltucuman.com.ar/images/logo-sat-2.png"
                                                width="230px"></img>
                                                </div>

                                                    <CardBody className="p-0">
                                                        <form className="login-form mt-4" onSubmit={handleLogin}>
                                                            <Row>
                                                                <Col lg={12} >
                                                                    <div className="mb-3">
                                                                        <label className="form-label fw-semibold" htmlFor='email'>Email <span className="text-danger">*</span></label>
                                                                        <input type="email" className="form-control" placeholder="Email" id='email' name="email" required onChange={(e) => setEmail(e.target.value)} />
                                                                    </div>
                                                                </Col>

                                                                <Col lg={12} >
                                                                    <div className="mb-3">
                                                                        <label className="form-label fw-semibold" htmlFor='password'>Contraseña <span className="text-danger">*</span></label>
                                                                        <input type="password" value={password} className="form-control" id='password' placeholder="Contraseña" required onChange={(e) => setPassword(e.target.value)} />
                                                                    </div>
                                                                </Col>

                                                                <div className="col-lg-12 mb-0 mt-3">
                                                                    <div className="d-grid">
                                                                        <button className="btn btn-primary" type="submit">Iniciar Sesión</button>
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </form>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                            <div className="mb-md-5 text-center">
                                                <p className="mb-0 text-white">© {(new Date().getFullYear())}{" "} </p>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}